import feathers from "@feathersjs/client";
import io from "socket.io-client";

const feathersClient = feathers();

feathersClient.configure(
    feathers.socketio(
        io("https://sistema.mezzanotte.com.ar/", { transports: ["websocket"] })
    )
);
feathersClient.configure(
    feathers.authentication({
        storage: window.localStorage,
    })
);

export default feathersClient;
