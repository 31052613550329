import React, { useState, useEffect } from "react";
import feathersClient from "../../feathersClient";
import Reparacion from "./Reparacion";

function Reparaciones({
    dialog,
    setDialog,
    activo,
    setActivo,
    fabricantes,
    modelos
}) {
    const [cliente, setCliente] = useState({});
    const [reparaciones, setReparaciones] = useState({
        total: 0,
        limit: 0,
        skip: 0,
        data: []
    });

    useEffect(() => {
        if (activo.id) {
            feathersClient
                .service("reparaciones")
                .find({
                    query: {
                        vehiculoId: activo.id,
                        $limit: 100,
                        $sort: {
                            createdAt: -1
                        }
                    }
                })
                .then(data => {
                    setReparaciones(data);
                })
                .catch(error => {
                    console.log("error", error);
                });
            feathersClient
                .service("clientes")
                .get(activo.clienteId)
                .then(nCliente => {
                    setCliente(nCliente);

                    const telefonos = (cliente.telefono || " ").split(" ");
                    const telefono1 = telefonos[0];
                    const telefono2 = telefonos[1];
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [activo, setActivo]);

    return (
        <>
            <ul id="vehiculoInfo">
                <li>
                    <button
                        className="back"
                        onClick={() => {
                            setActivo({ ...activo, id: "" });
                        }}
                    >
                        <i className="material-icons md-dark md-24">
                            arrow_back
                        </i>
                    </button>
                </li>
                <li className="info">
                    <button>
                        <b>{activo.patente}</b>
                        {activo.vin ? (
                            <i className="material-icons md-dark md-18">link</i>
                        ) : (
                            <i className="material-icons md-dark md-18">
                                link_off
                            </i>
                        )}
                    </button>
                    <ul>
                        <li>
                            <i className="material-icons md-dark md-18">code</i>
                            <div>
                                <p>
                                    {activo.vin ||
                                        "No se especificó numero de chasis"}
                                </p>
                                <span>VIN</span>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>{activo.auto}</p>
                    <span>{activo.year}</span>
                    <i className="tag">{activo.combustible}</i>
                </li>
                <li className="info">
                    <button>
                        <i className="material-icons md-dark md-24">person</i>
                        {cliente.nombre} {cliente.apellido}
                    </button>
                    <ul>
                        <li>
                            <h5>
                                {cliente.nombre} {cliente.apellido}
                            </h5>
                            <button
                                onClick={() => {
                                    setDialog({
                                        tipo: "addCliente",
                                        id: cliente.id,
                                        nombre: cliente.nombre,
                                        apellido: cliente.apellido,
                                        telefono1: activo.telefonos[0],
                                        telefono2: activo.telefonos[1],
                                        direccion: cliente.direccion,
                                        empresa: cliente.empresa
                                    });
                                }}
                            >
                                <i className="material-icons md-dark md-24">
                                    more_vert
                                </i>
                            </button>
                        </li>
                        <li>
                            <i className="material-icons md-dark md-18">
                                phone
                            </i>
                            <div>
                                <p>{cliente.telefono}</p>
                                <span>Telefonos</span>
                            </div>
                        </li>
                        {cliente.direccion && (
                            <li>
                                <i className="material-icons md-dark md-18">
                                    house
                                </i>
                                <div>
                                    <p>{cliente.direccion}</p>
                                    <span>Direccion</span>
                                </div>
                            </li>
                        )}
                        {cliente.empresa && (
                            <li>
                                <i className="material-icons md-dark md-18">
                                    work
                                </i>
                                <div>
                                    <p>{cliente.empresa}</p>
                                    <span>Empresa</span>
                                </div>
                            </li>
                        )}
                    </ul>
                </li>
            </ul>
            <div id="reparaciones">
                {reparaciones.data.length ? (
                    reparaciones.data.map(aReparacion => (
                        <Reparacion
                            key={aReparacion.id}
                            reparacion={aReparacion}
                            setDialog={setDialog}
                        />
                    ))
                ) : (
                    <div className="empty">
                        <i className="material-icons md-dark md-inactive md-48">
                            build
                        </i>
                        <h6>Aún no hay reparaciones</h6>
                    </div>
                )}
                <div className="add">
                    <button
                        onClick={() => {
                            setDialog({
                                tipo: "addReparacion",
                                lista: activo.lista,
                                id: activo.id,
                                patente: activo.patente,
                                km:
                                    reparaciones.data.length &&
                                    reparaciones.data[0].km
                            });
                        }}
                    >
                        <i className="material-icons md-dark md-18">add</i>{" "}
                        Nueva Reparacion
                    </button>
                </div>
            </div>
        </>
    );
}

export default Reparaciones;
